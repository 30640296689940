import { graphql } from 'gatsby'
import 'gatsby-image'

export const query = graphql`
  fragment postFields on ContentfulBlogPost {
    id
    slug
    title
    publishDate(formatString: "MMMM Do, YYYY")
    description {
      description
    }
    category {
      id
      slug
      name
    }
    postCategories {
      id
      slug
      title
    }
    tags {
      id
      slug
      name
    }
    heroImage {
      fluid(maxWidth: 1200) {
        ...GatsbyContentfulFluid
      }
    }
    squareThumb: heroImage {
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid
      }
    }
    verticalThumb: verticalPhoto {
      fluid(maxWidth: 400, resizingBehavior: SCALE, quality: 80) {
        ...GatsbyContentfulFluid
      }
    }
  }
`
