import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
} from 'react-share'
import styled from '@emotion/styled'
import { Flex, Heading, Box, IconButton, Text, Tooltip } from '@pw/ui'
import { TiSocialPinterest as PinterestIcon } from 'react-icons/ti'
import { FaWhatsapp as WhatsappIcon } from 'react-icons/fa'
import {
  FiFacebook as FacebookIcon,
  FiInstagram as InstagramIcon,
  FiTwitter as TwitterIcon,
} from 'react-icons/fi'

import { buildImageUrl } from '../SEO'
import logoSrc from '../../images/logo.png'

const ShareBox = styled(Box)`
  > * {
    display: flex;
    align-items: center;
    margin-left: 0.5em;
    line-height: inherit;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    > span {
      line-height: inherit;
      display: flex;
      align-items: center;
    }

    > h6 {
      padding-right: 0.25em;
      line-height: inherit;
      display: flex;
      align-items: center;
    }
  }
`

const PostShare = ({ post, url, showText = false, location = null, ...props }) => {
  if (!post) {
    return null
  }

  const title = post?.seo?.title || post?.title || 'Passport to Wellness'
  const description =
    post?.seo?.description?.description ||
    post?.seo?.description ||
    'Passport to Wellness a Wellness Travel Blog'

  const heroPhoto = post?.heroPhoto?.fluid?.src ?? false
  const heroPhotoUrl = heroPhoto ? buildImageUrl(heroPhoto) : logoSrc

  const pinterestTitle = post?.seo?.pinterestTitle || title
  const pinterestDescription =
    post?.seo?.pinterestDescription?.pinterestDescription || description
  const pinterestPhoto = post?.seo?.pinterestPinPhoto?.fixed?.src || heroPhoto || logoSrc
  const pinterestPhotoUrl = pinterestPhoto ? buildImageUrl(pinterestPhoto) : logoSrc

  const shareUrl = url || `${location?.origin ?? ''}/${post.slug}`

  return (
    <Flex {...props} alignItems="center" textTransform="uppercase">
      <Tooltip label="Share on Facebook" placement="top">
        <ShareBox>
          <FacebookShareButton url={shareUrl} style={{ marginLeft: '0' }}>
            <Text as="span" color="green.400" _hover={{ color: 'green.500' }}>
              <IconButton
                variant="outline"
                bg="green.100"
                _hover={{ bg: 'green.200' }}
                as="span"
                fontSize="lg"
                p="1"
                color="currentColor"
                icon={FacebookIcon}
              />
            </Text>
            {showText && <Heading as="h6">Facebook</Heading>}
          </FacebookShareButton>
        </ShareBox>
      </Tooltip>

      <Tooltip label="Share on Twitter" placement="top">
        <ShareBox>
          <TwitterShareButton url={shareUrl} title={title}>
            <Text as="span" color="green.400" _hover={{ color: 'green.500' }}>
              <IconButton
                variant="outline"
                bg="green.100"
                _hover={{ bg: 'green.200' }}
                as="span"
                fontSize="lg"
                p="1"
                color="currentColor"
                icon={TwitterIcon}
              />
            </Text>
            {showText && <Heading as="h6">Twitter</Heading>}
          </TwitterShareButton>
        </ShareBox>
      </Tooltip>

      <Tooltip label="Share on Pinterest" placement="top">
        <ShareBox>
          <PinterestShareButton
            url={shareUrl}
            title={pinterestTitle || title}
            description={pinterestDescription || description}
            media={pinterestPhotoUrl}
          >
            <Text as="span" color="green.400" _hover={{ color: 'green.500' }}>
              <IconButton
                variant="outline"
                bg="green.100"
                _hover={{ bg: 'green.200' }}
                as="span"
                fontSize="2xl"
                p="1"
                color="currentColor"
                icon={PinterestIcon}
              />
            </Text>
            {showText && <Heading as="h6">Pinterest</Heading>}
          </PinterestShareButton>
        </ShareBox>
      </Tooltip>

      <Tooltip label="Share on WhatsApp" placement="top">
        <ShareBox>
          <WhatsappShareButton url={shareUrl} title={title}>
            <Text as="span" color="green.400" _hover={{ color: 'green.500' }}>
              <IconButton
                variant="outline"
                bg="green.100"
                _hover={{ bg: 'green.200' }}
                as="span"
                fontSize="lg"
                p="1"
                color="currentColor"
                icon={WhatsappIcon}
              />
            </Text>
            {showText && <Heading as="h6">WhatsApp</Heading>}
          </WhatsappShareButton>
        </ShareBox>
      </Tooltip>
    </Flex>
  )
}

export default PostShare
