import React from 'react'
import get from '@pw-utils/get'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'
import {
  Link,
  Text,
  List,
  ListItem,
  ListIcon,
  Box,
  Flex,
  Button,
  Heading,
  Image,
  useTheme,
} from '@pw/ui'
import { FaCircle } from 'react-icons/fa'

import { NewsletterForm } from '../Layout'

import ComePinWithMe from '../../images/come-pin-with-me.png'

export const Widget = (props) => {
  const theme = useTheme()

  return (
    <Box
      p="2"
      rounded="sm"
      borderWidth="1px"
      borderColor="darkBeige"
      boxShadow={theme.shadows.sm}
      mb="10"
      {...props}
    />
  )
}

export const WidgetHeading = (props) => (
  <Heading
    as="h5"
    textAlign="center"
    mb="4"
    p="4"
    bg="green.100"
    color="white"
    {...props}
  />
)

const Sidebar = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(limit: 5) {
        edges {
          node {
            id
            slug
            title
            publishDate(formatString: "MMMM Do YYYY")
            description {
              description
            }
            heroImage {
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }

      allContentfulTag {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  `)

  const posts = get(data, 'allContentfulBlogPost.edges', [])

  const tags = get(data, 'allContentfulTag.edges', []).filter(({ node: cat }) =>
    Boolean(cat.name),
  )

  return (
    <Box as="aside" flex="1" w="100%" maxWidth={{ md: '300px' }} {...props}>
      <Widget>
        <WidgetHeading>About Me</WidgetHeading>

        <Text p="3">
          Hi there! I’m Brittney. I’m a travel-obsessed wellness junkie who believes in
          maintaining a healthy lifestyle on the go. I’m here to share good eats, unique
          stays, and active adventures to help you #TravelWell.
        </Text>
      </Widget>

      <Widget>
        <Flex alignItems="center" justifyContent="stretch" wrap="wrap">
          <Button
            variant="outline"
            color="green.200"
            borderColor="green.200"
            borderWidth="1px"
            _hover={{ bg: 'green.200', color: 'white', textDecoration: 'none' }}
            py="6"
            as={GatsbyLink}
            to="/about"
            minWidth="45%"
            rounded="0"
            flex="1"
            m="1"
          >
            About Me
          </Button>

          <Button
            variant="outline"
            color="green.200"
            borderColor="green.200"
            borderWidth="1px"
            _hover={{ bg: 'green.200', color: 'white', textDecoration: 'none' }}
            py="6"
            as={GatsbyLink}
            to="/contact"
            minWidth="45%"
            rounded="0"
            flex="1"
            m="1"
          >
            Contact
          </Button>

          <Button
            variant="outline"
            color="green.200"
            borderColor="green.200"
            borderWidth="1px"
            _hover={{ bg: 'green.200', color: 'white', textDecoration: 'none' }}
            py="6"
            as={GatsbyLink}
            to="/work-with-me"
            minWidth="45%"
            rounded="0"
            flex="2"
            m="1"
          >
            Work With Me
          </Button>
        </Flex>
      </Widget>

      <Widget>
        <Link to="http://pinterest.com/passporttowellness" target="_blank">
          <Image src={ComePinWithMe} />
        </Link>
      </Widget>

      <Widget>
        <WidgetHeading>Recent Posts</WidgetHeading>

        <List p="3">
          {posts.map(({ node: post }) => (
            <ListItem key={post.id}>
              <Link as={GatsbyLink} to={`/${post.slug}`}>
                <ListIcon icon={FaCircle} size="8px" color="gray.600" />
                {post.title}
              </Link>
            </ListItem>
          ))}
        </List>
      </Widget>

      <Widget>
        <WidgetHeading>Newsletter</WidgetHeading>

        <Box p="3">
          <NewsletterForm
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          />
        </Box>
      </Widget>

      <Widget>
        <WidgetHeading>Tags</WidgetHeading>

        <Flex alignItems="center" justifyContent="center" wrap="wrap">
          {tags.map(({ node: tag }) => (
            <Button
              key={tag.id}
              to={`/tag/${tag.slug}`}
              variant="outline"
              variantColor="green"
              color="green.400"
              opacity="0.4"
              fontSize="xs"
              lineHeight="1"
              _hover={{ opacity: 1, textDecoration: 'none' }}
              textDecoration="none"
              m="1"
              p="2"
              height="inherit"
            >
              {tag.name}
            </Button>
          ))}
        </Flex>
      </Widget>

      <Widget color="gray.500" fontSize="80%" textAlign="center">
        <Link
          target="_blank"
          href="https://amzn.to/2EnBLJS"
          display="block"
          textAlign="center"
        >
          Try Audible and Get Two Free Audiobooks
        </Link>
        <img
          src="//ir-na.amazon-adsystem.com/e/ir?t=everydayayers-20&l=pf4&o=1"
          width="1"
          height="1"
          border="0"
          alt=""
          style={{ border: 'none !important', margin: '0px !important' }}
        />
      </Widget>
    </Box>
  )
}

export default Sidebar
