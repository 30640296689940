import React from 'react'
import { Flex, Text, Badge, Link } from '@pw/ui'
import get from '@pw-utils/get'

const PostMeta = ({ post, ...props }) => {
  const category = get(post, 'category', {})
  const tags = get(post, 'tags', []).filter(({ slug }) => Boolean(slug))

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      textTransform="uppercase"
      color="gray.500"
      {...props}
    >
      <Text mr="2">{post.publishDate}</Text>

      <Flex alignItems="center" mt="1px">
        {category.slug && (
          <Badge
            as={Link}
            px="2"
            py="1"
            ml="1"
            variant="subtle"
            variantColor="primary"
            color="primary.400"
            to={`/${category.slug}`}
            _hover={{ textDecoration: 'none' }}
          >
            {category.name}
          </Badge>
        )}

        {/* {tags.map(({ name, slug }) => (
          <Badge
            key={slug}
            as={Link}
            px="2"
            py="1"
            variant="outline"
            variantColor="green"
            to={`/tag/${slug}`}
            _hover={{ textDecoration: 'none' }}
            ml="1"
          >
            {name}
          </Badge>
        ))} */}
      </Flex>
    </Flex>
  )
}

export default PostMeta
